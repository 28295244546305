<template>
  <div class="main-layout"  >


      <img class="back-icon" src="../../assets/common/icon_swipe_left.png" alt="" @click="$router.go(-1)">


      <div class="layout-flex">
         <div class="topinfo">

           <div style="width: 100%" v-if="data.productHashList && data.productHashList.length > 0">
             <div class="my-swipe">
               <van-swipe :ref="swipe"  :loop="false" :show-indicators="false" indicator-color="white" @change="onChange" >
                 <van-swipe-item v-for="(item,index) in data.productHashList" :key="index"  >

                   <div  @click="toProductDetail" >
                     <img  :style="'transform: scale('+  (index == data.bannerActive  ? 1 : 0.8) +')'" :src="item.image" alt="">
                   </div>

                 </van-swipe-item>

               </van-swipe>
             </div>

             <div class="digital-bottom">
               <p class="digital-name">{{data.productHashList[data.bannerActive].productName}}</p>
               <div class="div-number">
                 <div class="left-chain-logo">
                   <van-image :src="require('../../assets/mine/icon_mine_chain.png')" class="goods-bottom-img"  >
                   </van-image>
                 </div>
                 <p class="ntf-number">{{  (data.productHashList[data.bannerActive].number == null || data.productHashList[data.bannerActive].number == '') ? '编码生成中' : data.productHashList[data.bannerActive].number}}</p>
               </div>
               <p class="get-digital-time">{{data.productHashList[data.bannerActive].generatorTimeStr}}获得此徽章</p>

             </div>
           </div>


             <div  v-else class="no-has-digital">
               <img src="../../assets/nodata/icon_no_digitalList.png" alt="">
               <p>暂无徽章</p>

               <p class="no-has-digital-desc">您还未获得徽章</p>
             </div>


         </div>
         <van-tabs shrink sticky title-active-color="#FB4D09" title-inactive-color="#fff" v-model:active="data.active" swipeable>
           <van-tab v-for="(item,index) in data.seriseList" :key="index" :title="item.name">
             <div v-show="item.nodeList" >


              <div   v-for="(node,nodeIndex) in item.nodeList" :key="nodeIndex" class="tab-content"  :style="{margin:(nodeIndex > 0 ? '15px 0 0 0' : '0')}">

                <p class="tab-title">{{node.name}}</p>
                <div class="tab-content-list">

                  <div  v-for="(product,index) in node.productList" :key="index" class="item">
                    <img :src="product.images" alt="">
                    <p style="text-align: center" :style="{color:index== (node.productList.length -1 ) ?  '#868484' : '#fff'}" > {{ product.name }}</p>

                    <p v-if="product.hashList && product.hashList.length > 0" class="digital-num">{{ product.hashList.length}}</p>

                  </div>

                </div>


              </div>


             </div>
           </van-tab>
         </van-tabs>
       </div>
      <div class="botton-introduce">
        <div>
          <img class="chain-icon" src="../../assets/icons/chain_logo_big.png">
        </div>
        <div class="chain-botton">提供区块链技术服务支持</div>
      </div>




  </div>
</template>
<script>

import {getCurrentInstance, reactive, ref} from "vue";
import {useRouter} from "vue-router";
import {clearToast, showLoading} from "../../utils/util";

export default {
  components: {
  },
  setup() {


    //埋点上报
    eshimin.bytedanceApplogEvent({
      eventName: 'page_detail', // 例子
      eventParams: {'page_name': '我的-数字徽章','title_name': '我的-数字徽章'} ,//例子
      onResponse:  (res)=> {
        console.log("bytedanceApplogEvent responseData:" + res);
      }
    })

    const { proxy } = getCurrentInstance();
    const $router = useRouter();
    const swipe = ref(null)
    const data = reactive({
      bannerActive:0,
      active:0,
      seriseList:[],
      productHashList:[],
    })

    const onChange = (index) => {

      data.bannerActive = index

    }

    const toProductDetail = () => {

      $router.push({
        path: "/nftDetail",
        query: { productDetail: JSON.stringify(data.productHashList[data.bannerActive]), isShow: true }
      })
    }

    const loadData = () => {


      showLoading()
      proxy.$http.get("/wenwu-user/order/series").then(res => {
        clearToast()

        data.seriseList = [... res.data.data.seriseList];

        data.seriseList.forEach(
          (item)=>{




            if(item.nodeList){
              item.nodeList.forEach((node)=>{


                if(!node.productList){
                  node.productList = []
                }
                node.productList = [...node.productList,{
                  images:require('../../assets/mine/icon_digital_more.png'),
                  name:'即将上新'
                }]

              })
            }





          }
        )

        data.productHashList = [...res.data.data.productHashList]


      })
    }

    loadData();




    return {
      swipe,
      data,
      onChange,
      toProductDetail


    }

  }

}
</script>
<style scoped lang="scss" src="./index.scss">
</style>

